import PropTypes from "prop-types";
import { useEffect } from "react";
import { useResizeDetector } from "react-resize-detector";

function OnResize(props) {
  const { children, onResize } = props;

  const { width, height, ref } = useResizeDetector();

  useEffect(() => {
    if (onResize && width && height) {
      onResize({ width, height });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, height]);

  return <div ref={ref}>{children}</div>;
}

OnResize.propTypes = {
  onResize: PropTypes.func.isRequired,
  children: PropTypes.node,
};

OnResize.defaultProps = {
  children: null,
};

export default OnResize;
